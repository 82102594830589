<template>
  <div
    class="home"
    :style="{ paddingBottom: (isManage ? '140px' : '40px') }"
  >
    <van-list
      v-model="isLoading"
      :finished="!hasMore"
      finished-text="我也是有底线的"
      @load="loadMoreData"
    >
      <van-sticky>
        <div class="record-title flex">
          <div
            class="item"
            :style="{ color: (type == 1 ? colorData.specialColor : ''), fontWeight: (type == 1 ? 500 : '') }"
          >拍品 <div
              class="bg"
              :style="{ background: (type == 1 ? colorData.specialColor : '') }"
            ></div>
          </div>
          <div
            class="item"
            :style="{ color: (type == 2 ? colorData.specialColor : ''), fontWeight: (type == 2 ? 500 : '') }"
          >商城 <div
              class="bg"
              :style="{ background: (type == 2 ? colorData.specialColor : '') }"
            ></div>
          </div>
        </div>
      </van-sticky>
      <div class="product-list">
        <div
          class="top flex justify-b"
          v-if="total"
        >
          <div class="l">共{{ total }}个关注</div>
          <div
            class="r"
            @click="manageAction"
          ><span v-if="!isManage">管理</span><span
              :style="{ color: colorData.specialColor }"
              v-else
            >完成</span></div>
        </div>
        <div
          class="product"
          v-for="(m, i) in list"
          :key="`i${i}`"
        >
          <div class="item flex align-items-c">
            <div
              class="chiose"
              v-if="isManage"
              @click="changeProduct(i)"
            >
              <img
                v-if="m.isChange"
                src="https://artimg2.artart.cn/image/20220212/3038d0f938317f2daf04c5a58d175df4.png"
                alt=""
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220212/83994983f9b8c08830427234749cf8f8.png"
                alt=""
              >
            </div>
            <div
              class="flex-one flex align-items-c"
              style="overflow: hidden"
            >
              <AuctionList :m="m" />
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <div
      class="bottom-icon flex justify-b align-items-c"
      v-if="isManage"
    >
      <div
        class="chiose flex align-items-c"
        @click="changeAllAction"
      >
        <img
          v-if="changeAll"
          src="https://artimg2.artart.cn/image/20220212/3038d0f938317f2daf04c5a58d175df4.png"
          alt=""
        >
        <img
          v-else
          src="https://artimg2.artart.cn/image/20220212/83994983f9b8c08830427234749cf8f8.png"
          alt=""
        >
        <div>{{ changeAll ? '取消' : '' }}全选</div>
      </div>
      <div
        class="btn"
        :style="rightBtnStyle"
        @click="delAction"
      >删除选中</div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from "vue";
import { Toast, Dialog, Sticky } from "vant";
import { relationList, relationDel } from "@/api/user";
import { useStore } from "vuex";
import AuctionList from "./modules/AuctionList.vue"

export default {
  components: {
    [Sticky.name]: Sticky,
    AuctionList
  },
  setup() {
    const store = useStore();

    const type = ref(1);
    const total = ref(0);
    const isLoading = ref(false); // 列表加载控制器
    const hasMore = ref(true); // 是否有更多数据
    const queryParams = ref({
      page: 0,
      limit: 10,
    });
    const list = ref([]); // 列表

    onBeforeMount(() => {
      store.dispatch("user/getInfo");
    });

    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });

    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });

    const load = () => {
      relationList(queryParams.value)
        .then((res) => {
          if (queryParams.value.page == 1) {
            list.value = res.data.list;
          } else {
            list.value = list.value.concat(res.data.list);
          }

          total.value = res.data.count;

          if (list.value.length >= res.data.count) {
            hasMore.value = false;
          } else {
            hasMore.value = true;
          }
          isLoading.value = false;
        })
        .catch((message) => {
          Toast(message);
          isLoading.value = false;
        });
    };

    const userId = computed(() => {
      return store.getters.uid;
    });

    const loadMoreData = () => {
      // 查看更多
      if (!isLoading.value && hasMore.value) {
        isLoading.value = true;
        queryParams.value.page = queryParams.value.page + 1;
        load();
      }
    };

    const delAction = () => {
      const ids = [];
      list.value.forEach((m) => {
        if (m.isChange) {
          ids.push(m.type_id);
        }
      });
      if (ids.length > 0) {
        Dialog.confirm({
          title: "确定删除吗？",
        }).then(() => {
          relationDel({
            type_ids: ids.join(","),
            type: 11,
          }).then(() => {
            Toast("操作成功");
            isManage.value = false;
            hasMore.value = true;
            isLoading.value = true;
            queryParams.value.page = 1;
            load();
          });
        });
      }
      // relationDel;
    };

    const isManage = ref(false);
    const changeAll = ref(false);
    let changeNum = 0;
    const manageAction = () => {
      list.value.forEach((m) => {
        m.isChange = false;
      });
      changeNum = 0;
      changeAll.value = false;
      isManage.value = !isManage.value;
    };

    const changeProduct = (index) => {
      // 选择商品
      if (list.value[index].isChange) {
        changeNum -= 1;
      } else {
        changeNum += 1;
      }
      changeAll.value = changeNum === list.value.length;
      list.value[index].isChange = !list.value[index].isChange;
    };

    const changeAllAction = () => {
      // 全选/全不选
      list.value.forEach((m) => {
        m.isChange = !changeAll.value;
      });
      if (changeAll.value) {
        changeNum = 0;
      } else {
        changeNum = list.value.length;
      }
      changeAll.value = !changeAll.value;
    };

    return {
      type,
      isLoading,
      hasMore,
      list,
      userId,
      colorData,
      total,
      isManage,
      rightBtnStyle,
      changeAll,

      loadMoreData,
      delAction,
      manageAction,
      changeProduct,
      changeAllAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f7f7f7;
  padding-bottom: 40px;
}
.product-list {
  margin-top: 20px;
  background: #fff;
  .top {
    .l,
    .r {
      padding: 32px;
    }
    .l {
      color: #999;
    }
    .r {
      color: #000;
    }
  }
}
.chiose {
  width: 48px;
  height: 48px;
  margin-right: 20px;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}
.product {
  padding: 0 32px 32px 32px;
  .item {
    padding-top: 20px;
    margin-top: 20px;

    &:first-child {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }
  }
}

.record-title {
  padding: 0 8px;
  background: #fff;
  .item {
    color: #666666;
    font-size: 28px;
    padding: 0 24px;
    height: 96px;
    line-height: 96px;
    position: relative;
    flex: 1;
    text-align: center;

    .bg {
      position: absolute;
      left: 50%;
      bottom: 18px;
      width: 36px;
      height: 6px;
      border-radius: 4px;
      margin-left: -18px;
    }
  }
}

.bottom-icon {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 96px;
  background: #fff;

  .chiose {
    width: 200px;
    padding-left: 32px;
    color: #666;

    img {
      width: 48px;
      margin-right: 10px;
    }
  }

  .btn {
    width: 200px;
    height: 96px;
    text-align: center;
    line-height: 96px;
    border-radius: 0 !important;
  }
}
</style>